<template>
    <div class="text-center">
        <h3>
            Not Found
        </h3>
        <p>
            Oops, we couldn't find that page. Try going
            <router-link :to="{name: 'ValidationSingle'}">
                validation page
            </router-link>
        </p>
    </div>
</template>
